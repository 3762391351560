<template>
  <img alt="app" src="./assets/logo.png">

</template>
<script>
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 120px;
}
#btn{
  margin-top: 40px;
}

</style>
